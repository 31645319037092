<template>
  <div id="addPassagewayDialog">
    <el-dialog
      title="新建出入口"
      :visible.sync="show"
      :close-on-press-escape="false"
      @closed="reset"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
        label-width="100px"
      >
        <el-form-item label="出入口名称" prop="channelName">
          <el-input
            v-model="addForm.channelName"
            placeholder="请输入出入口名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="出入口类型" prop="channelType">
          <el-radio-group v-model="addForm.channelType">
            <el-radio label="出口"></el-radio>
            <el-radio label="入口"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="停车场" prop="parkingLotId">
          <el-select
            v-model="addForm.parkingLotId"
            placeholder="请选择停车场"
            clearable
          >
            <el-option
              v-for="item of parkingLotList"
              :key="item.parkingLotId"
              :label="item.parkingLotName"
              :value="item.parkingLotId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="过场道闸">
          <el-select v-model="addForm.parkingAisle" placeholder="请选过场道闸">
            <el-option label="是" value="是"></el-option>
            <el-option label="否" value="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="播报音量" prop="soundVolume">
          <el-input
            v-model="addForm.soundVolume"
            placeholder="默认：100，请输入0-100的整数"
          ></el-input>
        </el-form-item>
        <el-form-item label="出入口位置">
          <el-input
            v-model="addForm.channelAddress"
            placeholder="请输入出入口位置"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["getList"],
  data() {
    return {
      show: false,
      loading: false,
      addForm: {
        channelName: "",
        channelType: "出口",
        soundVolume: "",
        channelAddress: "",
        parkingLotId: "",
        parkingAisle: "否",
      },
      addFormRules: {
        channelName: [
          { required: true, message: "请输入车牌号", trigger: "blur" },
        ],
        soundVolume: [
          {
            pattern: /^((?!0)\d{1,2}|100)$/,
            message: "请输入0-100的整数",
            trigger: "blur",
          },
        ],
        channelType: [
          { required: true, message: "请选择出入口类型", trigger: "change" },
        ],
        parkingLotId: [
          { required: true, message: "请选择停车场", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
  },
  methods: {
    reset() {
      this.$set(this, "addForm", {
        channelName: "",
        channelType: "出口",
        channelAddress: "",
        soundVolume: "",
        parkingLotId: "",
        parkingAisle: "否",
      });
      this.$refs["addForm"].resetFields();
    },
    async confirmBtn() {
      let falg = true;
      this.$refs["addForm"].validate((valid) => {
        if (!valid) {
          falg = false;
        }
      });
      if (!falg) {
        return;
      }
      try {
        this.loading = true;
        let res = await this.$http.post("/channel/add", {
          ...this.addForm,
        });
        if (res.code === 0) {
          this.getList();
          this.$message.success("添加成功");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#addPassagewayDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
        .soundVolume {
          .el-form-item__content {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
